<template>
  <div>
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="banner">
        <img src="../../assets/images/index9.jpg" alt="">
      </div>

      <div class="page">
        <div class="title-warp">
          <div class="title">西安小院科技股份有限公司</div>
          <div class="english-title">Xi'an Xiaoyuan Technology Co. Ltd</div>
        </div>
        <div class="basic-warp">
          <div class="top">
            <div class="item">
              <img src="../../assets/images/lxwm1.png" alt="">
              <div class="basic">
                <div class="key">
                  电话
                </div>
                <div class="value">
                  400-900-5258
                </div>
              </div>
            </div>
            <div class="item">
              <img src="../../assets/images/lxwm2.png" alt="">
              <div class="basic">
                <div class="key">
                  邮箱
                </div>
                <div class="value">
                  im@xiaoyuan.com.cn
                </div>
              </div>
            </div>
            <div class="item">
              <img src="../../assets/images/lxwm3.png" alt="">
              <div class="basic">
                <div class="key">
                  邮编
                </div>
                <div class="value">
                  710065
                </div>
              </div>
            </div>
            <div class="item">
              <img src="../../assets/images/lxwm4.png" alt="">
              <div class="basic">
                <div class="key">
                  地址
                </div>
                <div class="value">
                  陕西省西安市雁塔区朱雀大街3号8幢
                </div>
              </div>
            </div>
            <div class="item">
              <img src="../../assets/images/lxwm5.png" alt="">
              <div class="basic">
                <div class="key">
                  公司主页
                </div>
                <div class="value">
                  http://www.xiaoyuan.com.cn
                </div>
              </div>
            </div>
          </div>

          <div class="bottom">
            <div class="item">
              <img src="../../assets/images/lxwm6.png" alt="">
              <div class="item-warp">
                <div class="title">小院生活公众号</div>
                <div class="desc">扫码关注了解更多小院动态</div>
                <img src="../../assets/images/qrcode1.png" alt="" class="qrcode">
              </div>
            </div>

            <div class="item">
              <img src="../../assets/images/lxwm7.png" alt="">
              <div class="item-warp">
                <div class="title">小院生活小程序</div>
                <div class="desc">预订小院产品、门店消费、图书借阅</div>
                <img src="../../assets/images/minicode.jpg" alt="" class="qrcode">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'

export default {
  data () {
    return {

    }
  },
  components: {
    Header2022,
    Footer2022,
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding-top: 64px;
}

.content .banner>img {
  width: 100%;
}

.content .page {
  width: 1200px;
  margin: auto;
  padding: 80px 50px 100px;
  box-sizing: border-box;
  color: #303233;
  font-size: 16px;
}

.content .page .title-warp {
  width: 100%;
  border-bottom: 1px solid #F4F4F4;
  padding-bottom: 39px;
}

.content .page .title-warp .title {
  font-size: 26px;
  line-height: 26px;
}

.content .page .title-warp .english-title {
  font-size: 14px;
  line-height: 14px;
  color: #999999;
  margin-top: 18px;
}

.content .page .basic-warp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.content .page .basic-warp {
  padding-top: 40px;
}

.content .page .basic-warp .top {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #F4F4F4;
}

.content .page .basic-warp .top .item {
  width: 33.333%;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.content .page .basic-warp .top .item>img {
  width: 48px;
  height: 48px;
}

.content .page .basic-warp .top .item>.basic {
  flex: 1;
  margin-left: 30px;
}

.content .page .basic-warp .top .item>.basic .key {
  font-size: 16px;
  line-height: 16px;
}

.content .page .basic-warp .top .item>.basic .value {
  font-size: 16px;
  line-height: 16px;
  color: #999999;
  margin-top: 16px;
}

.content .page .basic-warp .bottom {
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.content .page .basic-warp .bottom .item {
  width: 33.3%;
  display: flex;
}

.content .page .basic-warp .bottom .item>img {
  width: 48px;
  height: 48px;
}

.content .page .basic-warp .bottom .item>.item-warp {
  flex: 1;
  margin-left: 30px;
}

.content .page .basic-warp .bottom .item>.item-warp .title {
  font-size: 16px;
  line-height: 16px;
}

.content .page .basic-warp .bottom .item>.item-warp .desc {
  font-size: 16px;
  line-height: 16px;
  color: #999999;
  margin-top: 18px;
}

.content .page .basic-warp .bottom .item>.item-warp .qrcode {
  width: 150px;
  height: 150px;
  margin-top: 18px;
}
</style>